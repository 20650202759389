<template>
  <v-btn
    v-if="(job == null || job.status == null) && this.case != null"
    icon
    :disabled="preventAction"
    @click.stop="startJob"
  >
    <v-icon>directions_run</v-icon>
    <v-dialog v-model="invalidCaseDialog" max-width="600">
      <v-card>
        <v-alert type="error" tile> Error! </v-alert>
        <v-card-text class="pt-2">
          This case has errors! Are you sure you want to run this job?
          <v-list dense>
            <v-list-item>
              <v-list-item-title> Errors (total: {{ caseErrors.length }}): </v-list-item-title>
            </v-list-item>
            <v-list-item v-for="(error, i) in dialogErrors" :key="i" class="error-item">
              <v-list-item-subtitle>
                {{ parseError(error) }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="caseErrors.length > 5">
              <v-tooltip top>
                <template #activator="{ on }">
                  <p
                    class="text-clickable caption"
                    v-on="on"
                    @click="showAllErrors = !showAllErrors"
                  >
                    {{ showAllErrors ? "Collapse" : "..." }}
                  </p>
                </template>
                <span>{{ showAllErrors ? "Collapse items" : "Show all" }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="invalidCaseDialog = false"> Nevermind </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="startJob()"> Run Anyways </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
  <v-progress-circular
    v-else-if="job.status === 'IN_PROGRESS'"
    :indeterminate="job.type_name === 'ALTERNATIVE_ANALYSIS' ? true : false"
    :value="job.type_name === 'ALTERNATIVE_ANALYSIS' ? 0 : job.progress"
    color="secondary"
    size="25"
  >
    <v-tooltip top v-if="!preventAction">
      <template #activator="{ on }">
        <v-btn icon @click.stop="cancelJob" v-on="on">
          <v-icon>cancel</v-icon>
        </v-btn>
      </template>
      <span>Stop Job</span>
    </v-tooltip>
  </v-progress-circular>
  <v-tooltip v-else-if="job.status === 'ERROR'" bottom>
    <template #activator="{ on }">
      <v-icon v-on="on" color="error">error</v-icon>
    </template>
    <span>{{ job.error }}</span>
  </v-tooltip>
  <v-tooltip top v-else-if="job.status === 'NOT_STARTED'">
    <template #activator="{ on }">
      <v-btn icon :readonly="!preventAction" @click.stop="cancelJob" v-on="on">
        <v-icon color="warning"> hourglass_empty </v-icon>
      </v-btn>
    </template>
    <span>Stop Job</span>
  </v-tooltip>
  <v-tooltip v-else-if="job.status === 'COMPLETE'" top>
    <template #activator="{ on }">
      <v-icon color="success" v-on="on"> check_circle </v-icon>
    </template>
    <span>Rerun Job</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { schemas, CaseModel } from "data-dictionary";
import Ajv from "ajv";
import profilesMixin from "@/mixins/profiles";

export default {
  name: "JobIndicator",
  components: {},
  mixins: [profilesMixin],
  props: {
    job: {
      type: Object,
      required: false,
      default: null,
    },
    case: {
      type: Object,
      required: false,
      default: null,
    },
    preventAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    jobType: {
      type: Number,
      required: true,
    },
    /* caseInProgress: {
      type: Boolean,
      required: false,
      default: false,
    }, */
  },
  data() {
    return {
      loadInterval: setInterval(this.reloadJob, 15 * 1000),
      caseErrors: [],
      ajv: new Ajv({
        schemas,
        allErrors: true,
        coerceTypes: true,
        jsonPointers: true,
      }),
      invalidCaseDialog: false,
      showAllErrors: false,
      // currentJob: this.job,
    };
  },
  computed: {
    currentJob() {
      return this.job;
    },
    ...mapState({}),
    ...mapGetters({
      getJobs: "jobs/find",
    }),
    dialogErrors() {
      if (this.showAllErrors) {
        return this.caseErrors;
      }
      return this.caseErrors.length > 5 ? this.caseErrors.slice(0, 5) : this.caseErrors;
    },
    /* caseInProgress() {
      return !!this.getJobs({
        query: { case_id: this.case.id },
      }).data.filter(job => ['NOT_STARTED', 'IN_PROGRESS'].includes(job.status))
        .length;
    },
    jobInProgress() {
      return ['NOT_STARTED', 'IN_PROGRESS'].includes(this.job.status);
    }, */
    validate() {
      return this.ajv.getSchema("case.schema.json");
    },
    currentCaseErrors() {
      const errors = this.caseErrors.reduce((acc, err) => {
        const path = err.dataPath.substring(1).split("/");
        this.$utils.pathBuilder(acc, path, err.message);
        return acc;
      }, {});

      return errors;
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.reloadJob();
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      getJob: "jobs/get",
      loadJobs: "jobs/find",
      createJob: "jobs/create",
    }),
    reloadJob() {
      if (this.currentJob && this.currentJob.id /* && this.currentJobInProgress */) {
        return this.getJob(this.currentJob.id);
      }

      if (this.case && this.case.id && this.jobType) {
        return this.loadJobs({
          query: { case_id: this.case.id, type: this.jobType },
        });
      }

      return null;
    },
    async startJob() {
      if (this.preventAction || this.case == null) return;
      const validateCase = await this.validateCase();
      if (!validateCase && !this.invalidCaseDialog) {
        this.invalidCaseDialog = true;
        return;
      }
      this.invalidCaseDialog = false;
      this.createJob({
        case_id: this.case.id,
        status: "NOT_STARTED",
        type: this.jobType,
      }).then((j) => {
        console.debug("Job Saved", j);
        this.currentJob = j;
      });
    },
    cancelJob() {
      this.currentJob.remove();
      this.currentJob = null;
    },
    async validateCase() {
      const validateCase = new CaseModel(this.case);
      const profileErrors = await this.validateProfiles(validateCase);
      if (!this.validate(validateCase) || profileErrors.length) {
        this.caseErrors = [
          ...(!this.validate(validateCase) ? this.validate.errors : []),
          ...profileErrors,
        ];
        console.error("This case has errors: ", this.caseErrors, this.currentCaseErrors);
        return false;
      }
      this.caseErrors = [];
      return true;
    },
    parseError(error) {
      return `${error.dataPath.substring(1).replace(/\//g, " > ").replace(/_/g, " ")}: ${
        error.message
      }`;
    },
  },
};
</script>

<style></style>
