var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.job == null || _vm.job.status == null) && this.case != null)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.preventAction},on:{"click":function($event){$event.stopPropagation();return _vm.startJob($event)}}},[_c('v-icon',[_vm._v("directions_run")]),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.invalidCaseDialog),callback:function ($$v) {_vm.invalidCaseDialog=$$v},expression:"invalidCaseDialog"}},[_c('v-card',[_c('v-alert',{attrs:{"type":"error","tile":""}},[_vm._v(" Error! ")]),_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" This case has errors! Are you sure you want to run this job? "),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Errors (total: "+_vm._s(_vm.caseErrors.length)+"): ")])],1),_vm._l((_vm.dialogErrors),function(error,i){return _c('v-list-item',{key:i,staticClass:"error-item"},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.parseError(error))+" ")])],1)}),(_vm.caseErrors.length > 5)?_c('v-list-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('p',_vm._g({staticClass:"text-clickable caption",on:{"click":function($event){_vm.showAllErrors = !_vm.showAllErrors}}},on),[_vm._v(" "+_vm._s(_vm.showAllErrors ? "Collapse" : "...")+" ")])]}}],null,false,3629996776)},[_c('span',[_vm._v(_vm._s(_vm.showAllErrors ? "Collapse items" : "Show all"))])])],1):_vm._e()],2)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.invalidCaseDialog = false}}},[_vm._v(" Nevermind ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.startJob()}}},[_vm._v(" Run Anyways ")])],1)],1)],1)],1):(_vm.job.status === 'IN_PROGRESS')?_c('v-progress-circular',{attrs:{"indeterminate":_vm.job.type_name === 'ALTERNATIVE_ANALYSIS' ? true : false,"value":_vm.job.type_name === 'ALTERNATIVE_ANALYSIS' ? 0 : _vm.job.progress,"color":"secondary","size":"25"}},[(!_vm.preventAction)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancelJob($event)}}},on),[_c('v-icon',[_vm._v("cancel")])],1)]}}],null,false,922109700)},[_c('span',[_vm._v("Stop Job")])]):_vm._e()],1):(_vm.job.status === 'ERROR')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("error")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.job.error))])]):(_vm.job.status === 'NOT_STARTED')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","readonly":!_vm.preventAction},on:{"click":function($event){$event.stopPropagation();return _vm.cancelJob($event)}}},on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" hourglass_empty ")])],1)]}}])},[_c('span',[_vm._v("Stop Job")])]):(_vm.job.status === 'COMPLETE')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v(" check_circle ")])]}}])},[_c('span',[_vm._v("Rerun Job")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }